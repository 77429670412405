import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { ReactComponent as WelcomeImage } from "./welcome-image.svg";

import "./index.scss";
import FileCheckIcon from "../../components/FileCheckIcon";
import Layout from "../../layout";
import useOrderId from "../../hooks/useOrderId";

const Welcome: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { getOrderId } = useOrderId();
  const orderId = getOrderId();

  const navigate = useNavigate();

  const onContinue = () => {
    navigate({
      pathname: '/survey',
      search: searchParams.toString(),
    });
  };

  return (
    <Layout
      title="Pre-Delivery Survey"
      leftAction={<FileCheckIcon />}
      actions={
        <LoadingButton
          onClick={onContinue}
          disabled={!orderId}
          className="primary-action"
          variant="contained"
          disableElevation
          disableRipple
        >
          <Typography variant="subtitle2">Continue</Typography>
        </LoadingButton>
      }
    >
      <Box className="wrapper">
        <Box className="content">
          <Box className="image-wrapper">
            <WelcomeImage />
          </Box>
          <Box>
            <Typography variant="h4" className="content-title">Quick Survey</Typography>
            <Typography>Please answer the following questions for smoother delivery service!</Typography>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default Welcome;
