import { createBrowserRouter } from "react-router-dom";
import SurveyPage from "../pages/Survey";
import TipsTricks from "../pages/TipsTricks";
import Start from "../pages/Start";
import Error from "../pages/Error";
import RouteWrapper from "../pages/RouteWrapper";

const router = createBrowserRouter([
  {
    path: '/',
    element: <RouteWrapper><Start /></RouteWrapper>,
    errorElement: <Error />,
  },
  {
    path: '/survey',
    element: <RouteWrapper><SurveyPage /></RouteWrapper>,
    errorElement: <Error />,
  },
  {
    path: '/tips-tricks',
    element: <RouteWrapper><TipsTricks /></RouteWrapper>,
    errorElement: <Error />,
  },
  {
    path: '*',
    element: <Error />
  }
]);

export default router;
