import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import './index.scss';

export interface LayoutProps {
  className?: string;
  title?: string;
  children?: React.ReactNode;
  leftAction?: React.ReactNode;
  rightAction?: React.ReactNode;
  actions?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({
  className,
  title,
  children,
  leftAction,
  rightAction,
  actions,
}) => (
  <Container
    className={`app-layout ${className}`}
    maxWidth="sm"
    disableGutters
    sx={{ width: '100vw', display: 'flex' }}
  >
    <Box className="app-top-bar">
      {leftAction}
      {title && (
        <Typography className="app-page-title" variant="h5">
          {title}
        </Typography>
      )}
      {rightAction}
    </Box>

    <Box className="app-content" component="main">
      {children}
    </Box>

    {actions && <Box className="app-actions">{actions}</Box>}
  </Container>
);

export default Layout;
