import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

const useRetailerId = () => {
  const [searchParams] = useSearchParams();

  const paramKey = 'retailerId';

  const hasRetailerId = useCallback(
    () => searchParams.has(paramKey[0])
      || searchParams.has(paramKey) 
      || searchParams.has(paramKey.toLowerCase()),
    [searchParams]
  );

  const getRetailerId = useCallback(
    () => searchParams.get(paramKey[0])
      ?? searchParams.get(paramKey)
      ?? searchParams.get(paramKey.toLowerCase()),
    [searchParams]
  );

  return {
    hasRetailerId,
    getRetailerId,
  };
};

export default useRetailerId;
