import { Link, Typography } from "@mui/material";

import "../paragraph.scss";

const PPLink: React.FC = () => <Link href="https://docs.google.com/document/d/1rlUW8L9uNrQwLhrJshGquGQQq3Emd9Q54qNED1M322E/edit" target="_blank">Privacy Policy</Link>;

const TermsConditionsParagraph: React.FC = () => (
  <div className="paragraph-wrapper">
    <Typography variant="h5" className="subtitle">Description of Services</Typography>
    <Typography className="text">
      The Services consist of an application that directly allows users to visualize pieces of furniture within their own spaces and scan their spaces to receive personalized furniture recommendations.
    </Typography>
    <Typography variant="h5" className="subtitle">Your Commitments </Typography>
    <Typography className="text">
      In return for Dufrense (“we” or “our” or “us”) to make the commitment to provide the Services, we require you to make the below commitments to us.
    </Typography>
    <Typography variant="h5" className="subtitle">Eligibility</Typography>
    <Typography className="text">
      To be eligible to use the Services, you must be a resident of Canada or the United States. By using the Services, you confirm that you are a resident of Canada or the United States; if you are not a Canada or United States resident, do not use the Services.
    </Typography>
    <Typography variant="h5" className="subtitle">Accepting the Terms</Typography>
    <Typography className="text">
      By providing any information about yourself, you represent and warrant that all the information you provide via the Services is accurate, current and complete. You agree that all information you provide to us, through the Services or otherwise, is governed by our <PPLink />, and you consent to all actions we take with respect to your personal information consistent with our then-current Privacy Policy. We reserve the right to withdraw or amend the Services or any portion thereof in our sole discretion without notice. We will not be liable if for any reason the Services or any portion are unavailable at any time or for any period. You are responsible for ensuring that all persons who access the Services through your internet connection and devices are aware of these Terms and comply with them. Your user name, password or any other piece of account information created as part of our security procedures is confidential, and you must not disclose it to any other person or entity. We have the right to disable any user name, password or other identifier, whether chosen by you or provided by us, at any time.
    </Typography>
    <Typography className="text">
      Anyone may browse the public-facing pages of the App and Services, but by utilizing the app to visualize things in your own space, you are agreeing to these terms and conditions.
    </Typography>
    <Typography variant="h5" className="subtitle">What You May Not Do On Our Services</Typography>
    <Typography className="text">
      You are prohibited from using the Services: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Services or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Services or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Services for violating any of the prohibited uses.
    </Typography>
    <Typography variant="h5" className="subtitle">Permissions You Give to Us</Typography>
    <Typography className="text">
      As part of our agreement with you, you also give us permissions that we need in order to provide the Services.
      <ul>
        <li>
          We do not claim ownership of your personal content or information (being the videos scans or photos of your home), but you grant us a license to use it. We will not redistribute personal information collected in connection with the terms of Services, but retain the right to use this information to categorize and record information about you/ your home that help us provide better services to you as a customer. This includes adding information to your customer profile that allows us to provide more personalized product recommendations to you in the future. You can opt out of any direct marketing at any time.
        </li>
        <li>
          You agree that we may collect and use technical data and related information, including, but not limited to, usage data, location and other technical information about your device, system and application software, and peripherals, that is gathered periodically to facilitate the provision of software updates, product support, and other services to you (if any) related to the Services, and to anonymously track and report your activity inside of the Services, including for analytics purposes. Please see the <PPLink />.
        </li>
      </ul>
    </Typography>
    <Typography variant="h5" className="subtitle">Feedback</Typography>
    <Typography className="text">
      We appreciate hearing from our users and welcome your comments regarding the Services. Please be advised, however, that if you send us creative ideas, suggestions, inventions, or materials (“Creative Ideas”), we will:
      <ul>
        <li>own, exclusively, all now known or later discovered rights to the Creative Ideas;</li>
        <li>not be subject to any obligation of confidentiality and will not be liable for any use or disclosure of any Creative Ideas; and</li>
        <li>be entitled to unrestricted use of the Creative Ideas for any purpose whatsoever, commercial or otherwise, without compensation to you or any other person.</li>
      </ul>
    </Typography>
    <Typography variant="h5" className="subtitle">Limitation of Liability</Typography>
    <Typography className="text">
      IN NO EVENT WILL WE, OUR AFFILIATES OR OUR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR: (A) DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SERVICES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, OR LOSS OF DATA; OR (B) ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, DEVICES, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES OR TO YOUR DOWNLOADING OF ANY MATERIAL FROM THE SERVICES. YOUR USE OF THE SERVICES, ITS CONTENT, AND ANY FEATURES OR ITEMS OBTAINED THROUGH THE SERVICES IS AT YOUR OWN RISK. IN NO EVENT SHALL OUR LIABILITY TO YOU EXCEED THE AGGREGATE AMOUNT OF FEES PAID BY YOU FOR THE SERVICES IN THE SIX MONTH PERIOD PRIOR TO THE EVENT GIVING RISE TO SUCH LIABILITY. THE FOREGOING LIMITATIONS OF LIABILITY SET FORTH IN THIS SECTION APPLY TO THE FULL EXTENT PERMITTED BY APPLICABLE LAW: (I) NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY STATED IN THESE TERMS; (II) REGARDLESS OF WHETHER SUCH LIABILITY WAS FORESEEABLE OR WE WERE ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE; AND (III) WHETHER SUCH LIABILITY IS BASED IN CONTRACT, WARRANTY, TORT (INCLUDING ORDINARY NEGLIGENCE) OR ANY OTHER THEORY OF LIABILITY.
    </Typography>
    <Typography variant="h5" className="subtitle">Arbitration</Typography>
    <Typography className="text">
      EXCEPT FOR DISPUTES THAT QUALIFY FOR SMALL CLAIMS COURT, ALL DISPUTES ARISING OUT OF OR RELATED TO THIS AGREEMENT OR ANY ASPECT OF THE RELATIONSHIP BETWEEN YOU AND US, WHETHER BASED IN CONTRACT, TORT, STATUTE, FRAUD, MISREPRESENTATION OR ANY OTHER LEGAL THEORY, WILL BE RESOLVED THROUGH FINAL AND BINDING ARBITRATION BEFORE A NEUTRAL ARBITRATOR INSTEAD OF IN A COURT BY A JUDGE. SUCH DISPUTES INCLUDE, WITHOUT LIMITATION, DISPUTES ARISING OUT OF OR RELATING TO INTERPRETATION OR APPLICATION OF THIS ARBITRATION PROVISION, INCLUDING THE ENFORCEABILITY, REVOCABILITY OR VALIDITY OF THE ARBITRATION PROVISION OR ANY PORTION OF THE ARBITRATION PROVISION. ALL SUCH MATTERS SHALL BE DECIDED BY AN ARBITRATOR AND NOT BY A COURT OR JUDGE.
    </Typography>
    <Typography className="text">
      YOU AGREE THAT ANY ARBITRATION UNDER THIS AGREEMENT WILL TAKE PLACE ON AN INDIVIDUAL BASIS; CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED AND YOU ARE AGREEING TO GIVE UP THE ABILITY TO PARTICIPATE IN A CLASS ACTION.
    </Typography>
    <Typography variant="h5" className="subtitle">Waiver and Severability</Typography>
    <Typography className="text">
      No waiver of these Terms by us shall be deemed a further or continuing waiver of such term or condition or any other term or condition, and any failure of ours to assert a right or provision under these Terms shall not constitute a waiver of such right or provision. If any provision of these Terms is held by a court of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms will continue in full force and effect.
    </Typography>
    <Typography variant="h5" className="subtitle">App Terms</Typography>
    <Typography className="text">
      The use of an App either alone or in connection with the Services is governed by these Terms. In addition, you must comply with all applicable third party terms of agreement when using the Apps (for example, any agreement with a wireless service or wireless data provider or the app store or other portal from where you downloaded the App).
    </Typography>
    <Typography className="text">
      Any App made available to you by us is licensed (not sold) to users. Subject to your compliance with these Terms, and solely for so long as you are permitted by us to use the App, we hereby permit you, on a limited, non-exclusive, revocable, non-transferable, non-sublicensable basis, to install and use the App on a device that you own or control, solely for your personal, non-commercial use. If you fail to comply with any of the terms or conditions of these Terms, you must immediately cease using the App.
    </Typography>
    <Typography variant="h5" className="subtitle">Changes to the Terms</Typography>
    <Typography className="text">
      We amend these Terms from time to time. We will use commercially reasonable efforts to generally notify all users of any material changes to these Terms,  however, you should look at the Terms regularly to check for such changes. We will also update the “Last Updated” date at the top of these Terms, which reflect the effective date of such Terms. Your continued access or use of the Services after the date of the new Terms constitutes your acceptance of the new Terms. If you do not agree to the new Terms, you must stop accessing or using the Services.
    </Typography>
  </div>
);

export default TermsConditionsParagraph;
