import { useEffect, useState } from "react";
import { useMixpanel } from "react-mixpanel-browser";
import Cookies from "js-cookie";
import Welcome from "../Welcome";
import TermsConditions from "../TermsConditions";

import { useCustomerIdQuery } from "../../api";
import useOrderId from "../../hooks/useOrderId";

const Start = () => {
  const { getOrderId } = useOrderId();
  const orderId = (getOrderId()) as string;

  const { data: { customer_id } = {} } = useCustomerIdQuery(orderId);

  const has_accepted_terms = Cookies.get('tc-signed') === 'true';

  const [tcSigned, setTcSigned] = useState<boolean>(has_accepted_terms);

  const mixpanel = useMixpanel();

  useEffect(() => {
    if (!mixpanel.config.token || !customer_id) return;

    mixpanel.identify(customer_id);
    mixpanel.people.set_once({
      has_accepted_terms,
      has_clicked_AR: false,
      surveys_completed: 0,
    });
  }, [mixpanel, customer_id, has_accepted_terms]);

  return tcSigned ? <Welcome /> : <TermsConditions onContinue={() => setTcSigned(true)} />
};

export default Start;
