import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type {
  GetARLinkResponse,
  UpdateSurveyRequest,
  GetCustomerResponse,
} from "../types/api";

const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  endpoints: (builder) => ({
    getARLink: builder.query<GetARLinkResponse, string>({
      query: (orderId) => ({
        url: `orders/${orderId}/ar-link`,
        method: 'GET',
      }),
    }),
    updateSurvey: builder.mutation<null, UpdateSurveyRequest>({
      query: ({ orderId, survey_results, customer }) => ({
        url: `surveys/user-surveys/${orderId}/customer`,
        method: 'PUT',
        body: { survey_results, customer },
      }),
    }),
    getCustomerFromOrder: builder.query<GetCustomerResponse, string>({
      query: (id) => `orders/${id}/customer`,
    }),
    checkOrder: builder.query<object, string>({
      query: (id) => `orders/${id}/check`,
    }),
  }),
});

export const {
  useGetARLinkQuery,
  useGetARLinkQuery: useCustomerIdQuery,
  useUpdateSurveyMutation,
  useLazyGetCustomerFromOrderQuery,
  useCheckOrderQuery,
} = api;
export default api;
