const OopsIllustration: React.FC = () => (
  <svg width="310" height="212" viewBox="0 0 310 212" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M275.508 74.9608C274.975 69.4541 275.686 65.5462 278.528 61.8159M38.9012 70.3423C39.1973 69.1581 39.6118 66.0791 38.9012 63.237C38.1907 60.3949 37.3025 58.8554 36.9473 58.4409" stroke="black" stroke-linecap="square"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M243.829 39.7744L245.555 40.1957C252.538 41.9005 256.042 42.6526 262.441 44.6012L263.105 48.5172L261.406 47.9998C255.091 46.0768 251.655 45.342 244.712 43.647L242.986 43.2257L243.829 39.7744ZM286.562 55.5008L288.032 56.4978C295.844 61.7957 301.237 68.0517 305.212 78.1861C309.172 88.284 310.822 101.269 309.609 118.252L309.483 120.024L305.939 119.771L306.066 117.999C307.255 101.346 305.61 88.9322 301.904 79.4832C298.213 70.0707 293.306 64.3672 286.038 59.4381V58.974L286.562 55.5008Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M69.3686 37.2876L67.6428 37.7083C63.2674 38.7236 57.5832 39.9016 55.2739 40.7392V40.7392C54.3353 41.0246 53.8058 42.0168 54.0912 42.9554V42.9554C54.3766 43.894 55.3688 44.4236 56.3074 44.1382V44.1382C59.7148 43.2766 64.8661 42.0986 68.4842 41.1599L70.21 40.7392L69.3686 37.2876ZM30.0501 51.8684L28.5793 52.8644C21.4893 57.6655 14.0723 63.0983 8.36504 72.2037C2.63689 81.3425 -0.929077 93.2366 0.212346 109.193L0.339092 110.965L3.88269 110.711L3.75595 108.939C2.66509 93.69 6.07082 82.5533 11.3753 74.0905C16.7006 65.5944 22.4183 61.1737 29.3396 56.4868L30.0501 55.9539V51.8684Z" fill="black"/>
    <path d="M171.061 56.1316C171.475 55.5395 172.339 54.1777 172.482 53.4671C172.659 52.579 171.593 52.7566 166.264 49.3816C167.389 49.3816 169.924 49.0263 171.061 47.6052M154.008 54.3553C154.541 49.3816 157.205 46.7171 159.159 45.8289M164.488 43.875C167.046 42.4539 168.633 42.0986 169.107 42.0986M163.067 50.8026C162.534 53.4671 161.468 54.7105 158.626 55.4211" stroke="black"/>
    <path d="M177.988 61.1055C173.903 60.395 170.172 63.9476 169.284 67.6779" stroke="black"/>
    <path d="M137.842 62.6563C132.289 61.8613 127.713 63.2709 128.961 70.3423C131.092 82.4213 143.468 79.6384 149.389 76.7371C162.889 81.3555 169.461 81.0003 180.119 76.7371C186.869 74.0371 189.178 62.6563 183.316 57.908C177.761 53.4078 171.593 46.3619 166.974 37.125L139.619 49.7369L149.389 66.4344C146.197 64.8879 141.775 63.2193 137.842 62.6563ZM137.842 62.6563L128.961 54.0001" stroke="black"/>
    <circle cx="137.666" cy="41.0329" r="7.28294" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M169.639 17.0526C168.895 17.0526 168.177 17.1642 167.501 17.3715C166.759 14.1366 163.863 11.7236 160.403 11.7236C157.56 11.7236 155.099 13.3516 153.899 15.7258C152.527 15.9482 151.285 16.5535 150.283 17.4283C149.556 17.1846 148.778 17.0526 147.968 17.0526C143.946 17.0526 140.685 20.3133 140.685 24.3355C140.685 25.3476 140.892 26.3115 141.265 27.1873C141.195 27.2258 141.126 27.2653 141.057 27.306C140.133 26.8815 139.105 26.6448 138.021 26.6448C133.999 26.6448 130.738 29.9055 130.738 33.9277C130.738 34.7452 130.873 35.5312 131.121 36.2647C130.387 36.0163 129.601 35.8817 128.784 35.8817C124.762 35.8817 121.501 39.1423 121.501 43.1646C121.501 45.1394 122.287 46.9307 123.563 48.2425C123.377 48.8861 123.277 49.5664 123.277 50.2699C123.277 54.2922 126.538 57.5528 130.56 57.5528C132.964 57.5528 135.095 56.3886 136.422 54.5934C137.431 55.1222 138.579 55.4213 139.797 55.4213C143.819 55.4213 147.08 52.1606 147.08 48.1383C147.08 48.0782 147.079 48.0183 147.078 47.9585C147.138 47.96 147.198 47.9607 147.258 47.9607C149.954 47.9607 152.308 46.4954 153.567 44.3176C153.942 44.3771 154.327 44.408 154.718 44.408C157.278 44.408 159.53 43.0871 160.829 41.0896C162.123 42.2683 163.843 42.987 165.731 42.987C169.754 42.987 173.014 39.7263 173.014 35.704C173.014 34.0752 172.48 32.5712 171.576 31.3581C174.658 30.51 176.922 27.6872 176.922 24.3355C176.922 20.3133 173.662 17.0526 169.639 17.0526Z" fill="black"/>
    <path d="M169.461 79.5792L174.08 102.671M174.08 102.671C185.271 94.3228 196.284 93.0793 206.054 93.0793C214.104 93.0793 242.539 110.327 256.472 123.455M174.08 102.671C170.35 105.454 164.843 108.356 156.672 108.356C149.922 108.356 141.395 105.158 137.31 102.671M141.573 79.5792L137.31 102.671M137.31 102.671C128.606 97.3734 110.629 88.8517 93.4346 97.5201C76.2398 106.189 58.0857 120.79 51.1581 127.007C52.6976 104.921 56.3805 59.116 58.7963 52.5791C61.816 44.408 60.0397 40.5001 55.5989 44.408M55.5989 44.408C53.6183 46.1509 51.325 49.4101 49.3651 52.5791M55.5989 44.408C56.6647 42.0988 57.6239 37.0895 52.9344 35.5264C45.6515 36.7698 32.329 44.2304 30.7303 47.4278C29.1316 50.6251 28.7764 61.6384 33.3948 59.1515C38.0133 56.6646 47.572 55.3484 49.3651 52.5791M45.6515 59.1515C46.4637 57.5532 47.8093 55.0946 49.3651 52.5791M269.113 60.9278C268.453 59.2565 266.822 56.0594 264.373 52.9344M264.373 52.9344C263.091 51.2988 261.585 49.6829 259.876 48.3159C253.659 43.3422 256.501 56.3094 259.876 66.7897C262.576 75.174 263.133 113.152 263.074 131.093C261.761 128.873 259.447 126.258 256.472 123.455M264.373 52.9344C262.793 48.7304 260.581 40.3935 264.373 40.6777C269.113 41.033 286.521 44.9409 286.521 52.9344C286.521 56.9311 285.944 59.5956 284.789 60.9278C283.635 62.2601 281.903 62.2601 279.594 60.9278C275.899 58.7962 272.607 57.5528 271.423 57.1975M286.521 66.7897C292.206 79.52 303.716 109.137 304.285 125.764C304.944 145.058 302.389 193.44 249.574 177.959M236.429 173.192C241.124 175.189 245.499 176.764 249.574 177.959M249.574 177.959C247.35 183.771 243.237 191.788 239.982 191.018M256.472 123.455C242.231 135.889 232.876 165.021 236.429 184.027C237.295 188.659 238.556 190.681 239.982 191.018M239.982 191.018L210.317 173.192" stroke="black"/>
    <path d="M28.5991 62.5264C17.5859 73.0067 1.95421 134.468 15.2767 158.448C24.4739 175.003 47.3011 179.367 63.7704 177.327M80.2902 171.238C77.2651 174.208 71.1573 176.411 63.7704 177.327M80.2902 171.238C83.2508 175.205 85.0863 186.692 83.4876 191.665C83.0054 193.165 81.52 193.535 80.2902 193.529M80.2902 171.238C75.8494 162.178 64.4099 140.258 54.1782 125.053M63.7704 177.327C67.549 183.483 75.1218 193.501 80.2902 193.529M80.2902 193.529C81.6521 194.033 86.4363 192.66 94.6785 183.139C95.657 182.009 96.6001 180.88 97.5056 179.764M110.488 160.757C108.87 163.836 104.227 171.476 97.5056 179.764M97.5056 179.764L91.8363 211.738M225.238 211.738L215.291 177.327" stroke="black"/>
    <path d="M217.939 35.9089C217.096 41.2008 215.463 45.8295 213.522 49.0341C212.551 50.6376 211.517 51.862 210.487 52.6395C209.458 53.4159 208.472 53.7214 207.561 53.5764C206.651 53.4314 205.808 52.8345 205.071 51.777C204.334 50.718 203.731 49.2329 203.306 47.4071C202.457 43.7583 202.343 38.8511 203.186 33.5593C204.029 28.2674 205.662 23.6386 207.602 20.4341C208.573 18.8306 209.608 17.6062 210.638 16.8287C211.666 16.0523 212.653 15.7468 213.563 15.8918C214.473 16.0368 215.317 16.6337 216.053 17.6911C216.79 18.7501 217.393 20.2353 217.818 22.061C218.667 25.7099 218.781 30.617 217.939 35.9089Z" stroke="black"/>
    <ellipse cx="1.92512" cy="3.09413" rx="1.92512" ry="3.09413" transform="matrix(-0.987554 -0.157282 -0.157282 0.987554 213.672 24.0527)" fill="black"/>
    <ellipse cx="1.92512" cy="3.09413" rx="1.92512" ry="3.09413" transform="matrix(-0.987554 -0.157282 -0.157282 0.987554 211.186 38.6187)" fill="black"/>
    <path d="M217.815 54.8298C221.906 55.4813 226.519 47.8625 228.12 37.8128C229.72 27.763 227.702 19.088 223.611 18.4365" stroke="black"/>
    <path d="M207.484 54.0283C225.73 57.3 242.06 51.3688 243.959 40.7804C245.857 30.1921 232.605 18.9563 214.359 15.6846" stroke="black"/>
    <path d="M95.0502 33.1654C93.7561 27.9654 93.4489 23.0665 93.9814 19.3583C94.2479 17.5028 94.7209 15.9714 95.3646 14.853C96.0074 13.7362 96.7961 13.0691 97.6907 12.8464C98.5853 12.6238 99.5947 12.8434 100.686 13.5286C101.779 14.2149 102.914 15.3459 104.019 16.8601C106.228 19.8862 108.253 24.3576 109.547 29.5575C110.841 34.7575 111.148 39.6564 110.616 43.3646C110.349 45.2201 109.876 46.7515 109.232 47.8699C108.59 48.9867 107.801 49.6538 106.906 49.8765C106.012 50.0991 105.002 49.8795 103.911 49.1943C102.818 48.508 101.683 47.377 100.578 45.8628C98.3691 42.8367 96.3443 38.3653 95.0502 33.1654Z" stroke="black"/>
    <path d="M87.7622 15.7032C83.7427 16.7035 82.4767 25.5199 84.9343 35.395C87.392 45.2702 92.6427 52.4647 96.6621 51.4644" stroke="black"/>
    <path d="M97.5845 12.4001C79.5124 16.5254 66.8028 28.3714 69.1968 38.8589C71.5908 49.3463 88.1818 54.5038 106.254 50.3784" stroke="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M116.144 19.3262C116.365 20.2821 115.769 21.236 114.813 21.4568L103.427 24.087C102.471 24.3078 101.517 23.7119 101.296 22.7561C101.076 21.8002 101.671 20.8463 102.627 20.6255L114.013 17.9953C114.969 17.7745 115.923 18.3703 116.144 19.3262Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M119.366 34.7022C119.586 35.658 118.991 36.6119 118.035 36.8327L106.649 39.463C105.693 39.6838 104.739 39.0879 104.518 38.132C104.297 37.1762 104.893 36.2223 105.849 36.0015L117.235 33.3712C118.191 33.1504 119.145 33.7463 119.366 34.7022Z" fill="black"/>
    <path d="M177.864 100.318C177.996 107.479 168.403 113.463 156.436 113.684C144.47 113.904 134.662 108.279 134.529 101.119" stroke="black"/>
  </svg>
);

export default OopsIllustration;
