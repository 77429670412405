import { useMixpanel } from "react-mixpanel-browser";
import { Box, Link, Typography } from "@mui/material";
import { Slide } from "react-slideshow-image";
import Button from "@mui/material/Button";
import ClearPathIllustration from "../../components/ClearPathIllustration";
import MeasureIllustration from "../../components/MeasureIllustration";
import Layout from "../../layout";

import { useGetARLinkQuery } from "../../api";

import "react-slideshow-image/dist/styles.css";
import "./index.scss";
import AdviseUponArrivalIllustration from "../../components/AdviseUponArrivalIllustration";
import ARIcon from "./ARIcon";
import useOrderId from "../../hooks/useOrderId";
import useRetailerId from "../../hooks/useRetailerId";

const TipsTricks: React.FC = () => {
  const { getOrderId } = useOrderId();
  const { getRetailerId } = useRetailerId();
  const orderId = (getOrderId()) as string;
  const retailerId = (getRetailerId()) as string;

  const {
    data: { url, sku, customer_id } = {},
    isFetching: disableARLink,
    isError: isErrorARLink
  } = useGetARLinkQuery(orderId);

  const mixpanel = useMixpanel();

  const composeArViewerLink = () => {
    if (disableARLink || isErrorARLink) return process.env.REACT_APP_AR_VIEWER_DEFAULT_LINK;

    const arViewerLink = new URL(url as string);
    arViewerLink.searchParams.append('sku', (sku || process.env.REACT_APP_AR_VIEWER_DEFAULT_SKU) as string);
    arViewerLink.searchParams.append('customer_id', customer_id as string);
    arViewerLink.searchParams.append('retailer_id', retailerId);

    return arViewerLink.toString();
  };

  const handleARClick = async () => {
    await mixpanel.track(
      'Clicked on AR',
      {
        has_clicked_AR: true,
        retailer_id: retailerId,
        shopper_id: customer_id,
        order_id: orderId,
        app_id: process.env.REACT_APP_ID,
      },
      { send_immediately: true }, 
      () => {
        // eslint-disable-next-line no-restricted-globals
        location.href = composeArViewerLink() as string;
      });
  };

  return (
    <Layout
      title="Tips & Tricks"
    >
      <Box className="content">
        <Slide
          autoplay={false}
          arrows={false}
          infinite={false}
          indicators={true}
        >
          <Box>
            <Box className="image-wrapper">
              <ClearPathIllustration />
            </Box>
            <Typography variant="subtitle1" className="caption">
              Clear path for delivery team
            </Typography>
            <Typography variant="body2" className="small-caption">Swipe left</Typography>
          </Box>
          <Box>
            <Box className="image-wrapper">
              <MeasureIllustration />
            </Box>
            <Typography variant="subtitle1" className="caption">
              Measure doorways, hallways, stairways before delivery
            </Typography>
            <Typography variant="body2" className="small-caption">Swipe left</Typography>
          </Box>
          <Box>
            <Box className="image-wrapper">
              <AdviseUponArrivalIllustration />
            </Box>
            <Typography variant="subtitle1" className="caption">
              Advise the delivery team upon arrival where to place the product
            </Typography>
          </Box>
        </Slide>

        <Box className="ar-feature">
          <Button
            className="ar-feature-button"
            disabled={disableARLink}
          >
          <Link
            onClick={handleARClick}
            underline="none"
          >
            <ARIcon /> Try out our new AR Feature!
          </Link>
          </Button>

          <Typography variant="body2" className="small-caption">
            Try our new AR feature and see how these products fit in you space!
          </Typography>
        </Box>
      </Box>
    </Layout>
  );
};

export default TipsTricks;
