import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily:
      '"Poppins","Helvetica","Arial",sans-serif',
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem',
      lineHeight: 1.375,
    },
    subtitle1: {
      fontWeight: 700,
      lineHeight: 1.1875,
    },
    button: {
      textTransform: 'none',
    },
  },
});

export default theme;
