import useOrderId from "../../hooks/useOrderId";
import useRetailerId from "../../hooks/useRetailerId";

const RouteWrapper = ({ children }: any) => {
  const error = new Response('Not found', { status: 404 });

  const { hasOrderId } = useOrderId();
  const { hasRetailerId } = useRetailerId();

  if (!hasOrderId() || !hasRetailerId()) {
    throw error;
  }

  return children;
};

export default RouteWrapper;
