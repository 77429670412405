import { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom/client";
import { useMixpanel } from "react-mixpanel-browser";
import { useNavigate, useSearchParams } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Box, Typography } from '@mui/material';
import { Model, PanelModel, StylesManager } from "survey-core";
import { Survey } from "survey-react-ui";

import { useUpdateSurveyMutation, useLazyGetCustomerFromOrderQuery, useCustomerIdQuery } from "../../api";

import FileCheckIcon from "../../components/FileCheckIcon";
import Layout from "../../layout";

import surveyJSON from "./surveyJSON";

import "survey-core/defaultV2.css";
import "./index.scss";
import useOrderId from "../../hooks/useOrderId";
import useRetailerId from "../../hooks/useRetailerId";

StylesManager.applyTheme('defaultV2');

const classes = {
  root: 'sd-root-modern survey-root',
  header: 'survey-header',
  page: {
    root: 'survey-page-root',
  },
  panel: {
    container:
      'sd-element sd-element--complex sd-panel sd-row__panel survey-page-panel',
    header: 'sd-panel__header sd-element__header survey-panel-header',
    title: 'sd-title sd-element__title sd-panel__title survey-panel-title',
    content: 'sd-panel__content survey-panel-content',
    titleCollapsed: 'sd-element__title--collapsed survey-panel-collapsed',
    titleExpanded: 'sd-element__title--expanded survey-panel-expanded',
  },
  pageRow: 'sd-page__row survey-row',
  question: {
    mainRoot: 'sd-element sd-question sd-row__question survey-question',
    description:
      'sd-description sd-question__description survey-question-description',
  },
  checkbox: {
    item: 'sd-item sd-checkbox sd-selectbase__item survey-question-item',
    controlLabel: 'sd-item__control-label survey-question-label',
    itemChecked:
      'sd-item--checked sd-checkbox--checked survey-checkbox-checked',
    materialDecorator:
      'sd-item__decorator sd-checkbox__decorator survey-checkbox-decorator',
    label: 'sd-selectbase__label survey-base-label',
  },
  radiogroup: {
    item: 'sd-item sd-radio sd-selectbase__item survey-question-item',
    controlLabel: 'sd-item__control-label survey-question-label',
    itemChecked: 'sd-item--checked sd-radio--checked survey-radio-checked',
    materialDecorator:
      'sd-item__decorator sd-radio__decorator survey-radio-decorator',
    label: 'sd-selectbase__label survey-base-label',
  },
  dropdown: {
    item: 'sd-item sd-radio sd-selectbase__item survey-question-item',
    controlLabel: 'sd-item__control-label survey-question-label',
  },
  tagbox: {
    item: 'sd-item sd-radio sd-selectbase__item survey-question-item',
    controlLabel: 'sd-item__control-label survey-question-label',
  },
  actionBar: {
    root: 'sd-action-bar survey-action-bar',
  },
};

const appendCarets = (parent: Element) => {
  const carets = document.createElement('div');
  carets.classList.add('survey-header-caret');

  const root = ReactDOM.createRoot(carets);
  root.render(
    <>
      <KeyboardArrowDownIcon className="down-icon" />
      <KeyboardArrowUpIcon className="up-icon" />
    </>
  );

  parent.appendChild(carets);
};

const appendCaretsToSurveyPanels = (
  _: unknown,
  { panel, htmlElement }: { panel: PanelModel; htmlElement: HTMLElement }
) => {
  const el = htmlElement;
  if (el.getElementsByClassName('survey-header-caret').length > 0) {
    return;
  }

  appendCarets(el.getElementsByClassName('sd-title')[0]);
  panel.onPropertyChanged.add(
    (
      _: unknown,
      { name, newValue }: { name: string; newValue: string }
    ) => {
      if (name !== 'state') return;

      const down = el.getElementsByClassName('down-icon')[0] as HTMLElement;
      const up = el.getElementsByClassName('up-icon')[0] as HTMLElement;

      if (newValue === 'expanded') {
        up.style.display = 'initial';
        down.style.display = 'none';
      } else {
        up.style.display = 'none';
        down.style.display = 'initial';
      }
    }
  );
};

const SurveyPage = () => {
  const [searchParams] = useSearchParams();
  const { getOrderId } = useOrderId();
  const { getRetailerId } = useRetailerId();
  const orderId = (getOrderId()) as string;
  const retailerId = getRetailerId();

  const { data: { customer_id } = {}, isFetching: isFetchingCustomerId } = useCustomerIdQuery(orderId);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | Error>(null);

  const mixpanel = useMixpanel();
  const [answersToTrack, setAnswersToTrack] = useState<Array<any>>([]);

  const navigate = useNavigate();
  const survey = useMemo(() => new Model(surveyJSON), []);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);

  const [updateSurvey] = useUpdateSurveyMutation();
  const [getCustomer] = useLazyGetCustomerFromOrderQuery();

  survey.showCompletedPage = false;
  survey.hideRequiredErrors = true;
  survey.onAfterRenderPanel.add(appendCaretsToSurveyPanels);

  survey.onValueChanged.add(useCallback(
    (sender: Model, { name: survey_question, value: survey_response }) => {
      setSubmitDisabled(!sender.isCurrentPageValid);

      setAnswersToTrack(list => [...list, { survey_question, survey_response }]);
    },
    [setSubmitDisabled]
  ));

  useEffect(() => {
    if (!mixpanel.config.token || isFetchingCustomerId || answersToTrack.length === 0) return;

    answersToTrack.forEach(({ survey_question, survey_response }) => {
      mixpanel.track('Survey Questions Answered', {
        survey_question,
        survey_response,
        retailer_id: retailerId,
        shopper_id: customer_id,
        order_id: orderId,
        app_id: process.env.REACT_APP_ID,
      });
    });

    setAnswersToTrack([]);
  }, [answersToTrack, mixpanel, isFetchingCustomerId, customer_id, retailerId, orderId]);

  const handleSubmitSurvey = async () => {
    const surveyResults = survey.data;

    if (mixpanel.config.token) {
      mixpanel.track('Survey Completed', {
        responses_completed: Object.keys(surveyResults).length,
        retailer_id: retailerId,
        shopper_id: customer_id,
        order_id: orderId,
        app_id: process.env.REACT_APP_ID,
      });
      mixpanel.people.increment('surveys_completed');
    }

    setLoading(true);
    setError(null);
    try {
      const { data: customer = {} } = await getCustomer(orderId);

      await updateSurvey({
        orderId,
        survey_results: surveyResults,
        customer,
      });

      setLoading(false);
      navigate({
        pathname: '/tips-tricks',
        search: searchParams.toString(),
      });
    } catch (error) {
      setError(error as Error);
      setLoading(false);
      survey.clear();
    }
  };

  const onContinue = () => {
    if (survey.isCurrentPageValid) {
      handleSubmitSurvey();
    }
  };

  const SurveyForm = () => (
    <>
      <Box className="survey-page-title">
        <Typography variant="body1">
          Follow the questions listed here
        </Typography>
      </Box>

      <Survey model={survey} css={classes} />
    </>
  );

  return (
    <Layout
      title="Pre-Delivery Survey"
      className="survey-page"
      leftAction={<FileCheckIcon />}
      actions={
        <LoadingButton
          onClick={onContinue}
          disabled={submitDisabled}
          loading={loading}
          className="primary-action"
          variant="contained"
          disableElevation
          disableRipple
        >
          <Typography variant="subtitle2">Submit & Continue</Typography>
        </LoadingButton>
      }
    >
      {error && <Alert severity="error">Error on submitting survey</Alert>}
      <SurveyForm /> 
    </Layout>
  );
};

export default SurveyPage;
