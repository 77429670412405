import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { Warning } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

import "./index.scss";
import OopsIllustration from "../../components/OopsIllustraction";
import Layout from "../../layout";
import { LoadingButton } from "@mui/lab";

const Error: React.FC = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <Layout
      title="Error"
      leftAction={<Warning sx={{ color: '#E0E0E0' }} />}
      rightAction={<CloseIcon onClick={goBack} />}
      actions={
        <LoadingButton
          onClick={goBack}
          className="primary-action"
          variant="contained"
          disableElevation
          disableRipple
        >
          <Typography variant="subtitle2">Okay</Typography>
        </LoadingButton>
      }
    >
      <Box className="error-wrapper">
        <Box className="content">
          <Box className="image-wrapper">
            <OopsIllustration />
          </Box>
          <Box>
            <Typography variant="h4" className="content-title">Oops!</Typography>
            <Typography>
              Invalid link. Please double check and try again.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default Error;
