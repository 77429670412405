import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

const useOrderId = () => {
  const [searchParams] = useSearchParams();

  const paramKey = 'orderId';

  const hasOrderId = useCallback(
    () => searchParams.has(paramKey[0])
      || searchParams.has(paramKey) 
      || searchParams.has(paramKey.toLowerCase()),
    [searchParams]
  );

  const getOrderId = useCallback(
    () => searchParams.get(paramKey[0])
      ?? searchParams.get(paramKey)
      ?? searchParams.get(paramKey.toLowerCase()),
    [searchParams]
  );

  return {
    hasOrderId,
    getOrderId,
  };
};

export default useOrderId;
