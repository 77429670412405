import { useState } from "react";
import { useMixpanel } from 'react-mixpanel-browser';
import { CheckBox, CheckBoxOutlineBlankOutlined } from "@mui/icons-material";
import { Box, Link, Typography } from "@mui/material";
import Cookies from "js-cookie";
import FileCheckIcon from "../../components/FileCheckIcon";
import Layout from "../../layout";
import VirtualRealityIllustration from "../../components/VirtualRealityIllustration";

import "./index.scss";
import { LoadingButton } from "@mui/lab";
import TermsConditionsParagraph from "../../components/TermsConditionsParagraph";
import PrivacyPolicyParagraph from "../../components/PrivacyPolicyParagraph";
import useRetailerId from "../../hooks/useRetailerId";

interface TermsConditionsProps {
  onContinue: () => void;
};

const TermsConditions = ({ onContinue }: TermsConditionsProps) => {
  const { getRetailerId } = useRetailerId();
  const retailerId = getRetailerId();

  const [agree, setAgree] = useState<boolean>(false);
  const [currentView, setCurrentView] = useState<string>('illustration');
  const [hasReadFullTerms, setHasReadFullTerms] = useState<boolean>(false);
  const mixpanel = useMixpanel();

  const handleReadTCClick = () => {
    setCurrentView('terms-conditions');
    setHasReadFullTerms(true);
  };

  const handleAgree = () => setAgree(value => !value);

  const onTermsAccepted = () => {
    if (mixpanel.config.token) {
      mixpanel.track('Terms Accepted', {
        has_read_full_terms: hasReadFullTerms,
        retailer_id: retailerId,
        app_id: process.env.REACT_APP_ID,
      });
      mixpanel.people.set({
        has_accepted_terms: true,
        accepted_terms_timestamp: (new Date()),
      });
    }

    Cookies.set('tc-signed', 'true', {
      domain: window.location.hostname.split('.').slice(-2).join('.'),
      path: '/',
      expires: 60,
    });
    onContinue();
  };

  const Actions = () => (
    <>
      {currentView !== 'terms-conditions' && <Link
        href="#"
        onClick={() => handleReadTCClick()}
        color="#F68C2C"
        underline="none"
        sx={{
          display: 'block',
          marginBottom: '1em',
        }}
      >
        Read full Terms & Conditions
      </Link>}
      {currentView !== 'privacy-policy' && <Link
        href="#"
        onClick={() => setCurrentView('privacy-policy')}
        color="#F68C2C"
        underline="none"
        sx={{
          display: 'block',
          marginBottom: '1em',
        }}
      >
        Read full Privacy Policy
      </Link>}
      <Box className="agree-wrapper">
        {
          agree
          ? <CheckBox
              onClick={handleAgree}
              fontSize="large"
            />
          : <CheckBoxOutlineBlankOutlined
              onClick={handleAgree}
              fontSize="large"
            />}
        
        <Typography variant="body2" className="agree-text">
          I have read and agree to the full Terms and Conditions and Privacy Policy.
        </Typography>
      </Box>
      <LoadingButton
        onClick={onTermsAccepted}
        disabled={!agree}
        className="primary-action"
        variant="contained"
        disableElevation
        disableRipple
      >
        <Typography variant="subtitle2">Continue</Typography>
      </LoadingButton>
    </>
  );

  return (
    <Layout
      title={currentView === 'privacy-policy' ? 'Privacy Policy' : 'Terms and Conditions'}
      leftAction={<FileCheckIcon />}
      actions={<Actions />}
    >
      <Box className="wrapper">
          {currentView === 'illustration' && <VirtualRealityIllustration />}
          {currentView === 'terms-conditions' && <TermsConditionsParagraph />}
          {currentView === 'privacy-policy' && <PrivacyPolicyParagraph />}
      </Box>
    </Layout>
  );
};

export default TermsConditions;
