const AdviseUponArrivalIllustration: React.FC = () => (
  <svg width="390" height="287" viewBox="0 0 390 287" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="390" height="287" fill="white"/>
    <path d="M287.22 143.5V143.501C287.229 156.575 284.483 169.502 279.162 181.44H108.478C102.903 168.904 100.158 155.291 100.44 141.569C100.726 127.632 104.128 113.937 110.397 101.486C116.665 89.0355 125.641 78.146 136.667 69.616L136.138 68.9331L136.667 69.616C147.692 61.086 160.486 55.1323 174.112 52.1909C187.738 49.2495 201.849 49.3953 215.411 52.6177C228.973 55.84 241.642 62.0572 252.488 70.8132C263.335 79.5692 272.084 90.642 278.093 103.219C284.103 115.797 287.222 129.56 287.22 143.5Z" fill="#FFF0D9" stroke="#B25B0C" stroke-width="2"/>
    <path d="M101.78 182.44H285.86" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M91.1602 182.44H97.0602" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M290.58 182.44H296.48" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M275.097 213.555C275.093 213.556 275.09 213.558 275.086 213.56C274.807 213.689 274.36 213.828 273.733 213.972C272.486 214.258 270.628 214.537 268.21 214.805C263.381 215.34 256.382 215.823 247.722 216.229C230.405 217.041 206.475 217.543 180.04 217.543C153.605 217.543 129.675 217.041 112.358 216.229C103.698 215.823 96.699 215.34 91.8696 214.805C89.4521 214.537 87.5938 214.258 86.347 213.972C85.7196 213.828 85.2733 213.689 84.994 213.56C84.9903 213.558 84.9868 213.556 84.9832 213.555C84.9868 213.553 84.9903 213.551 84.9939 213.55C85.2733 213.421 85.7196 213.281 86.347 213.137C87.5938 212.852 89.4521 212.572 91.8696 212.304C96.699 211.769 103.698 211.286 112.358 210.88C129.675 210.068 153.605 209.566 180.04 209.566C206.475 209.566 230.405 210.068 247.722 210.88C256.382 211.286 263.381 211.769 268.21 212.304C270.628 212.572 272.486 212.852 273.733 213.137C274.36 213.281 274.807 213.421 275.086 213.55C275.09 213.551 275.093 213.553 275.097 213.555ZM84.7466 213.411C84.7467 213.411 84.7469 213.411 84.7474 213.412L84.7466 213.411ZM84.7466 213.698C84.7466 213.698 84.7469 213.698 84.7474 213.697L84.7466 213.698Z" fill="#FFDCA7" stroke="#B25B0C"/>
    <path d="M179.54 183.12V135.757C179.54 134.302 178.963 132.907 177.934 131.879C176.905 130.85 175.51 130.272 174.055 130.272H109.724H109.724C109.004 130.272 108.29 130.413 107.625 130.689L179.54 183.12ZM179.54 183.12H104.239L104.239 135.757L104.239 135.757C104.239 135.036 104.38 134.323 104.656 133.658L104.656 133.658C104.932 132.992 105.336 132.387 105.845 131.878C105.845 131.878 105.845 131.878 105.845 131.878L179.54 183.12Z" fill="white" stroke="#B25B0C"/>
    <path d="M180.04 183.62H103.739V135.757C103.739 134.971 103.893 134.192 104.194 133.466C104.495 132.74 104.936 132.08 105.491 131.524C106.047 130.968 106.707 130.528 107.433 130.227C108.16 129.926 108.938 129.772 109.724 129.772H174.055C175.643 129.772 177.165 130.403 178.287 131.525C179.41 132.647 180.04 134.17 180.04 135.757V183.62Z" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M255.848 135.757L255.839 183.12H180.54V135.757L180.54 135.757C180.54 135.036 180.682 134.322 180.958 133.656L180.958 133.656C181.234 132.99 181.638 132.385 182.148 131.875C182.658 131.366 183.264 130.962 183.93 130.687L183.93 130.687C184.596 130.412 185.31 130.271 186.031 130.272H186.032H250.366C251.086 130.272 251.799 130.414 252.464 130.69L252.464 130.69C253.13 130.965 253.734 131.369 254.243 131.878C254.752 132.388 255.156 132.993 255.431 133.658L255.432 133.658C255.707 134.323 255.849 135.036 255.848 135.757Z" fill="white" stroke="#B25B0C"/>
    <path d="M256.339 183.62H180.04V135.757C180.04 134.97 180.195 134.191 180.496 133.465C180.797 132.738 181.238 132.078 181.795 131.522C182.351 130.966 183.012 130.525 183.739 130.225C184.466 129.925 185.245 129.771 186.032 129.772H250.366C251.152 129.772 251.93 129.927 252.656 130.228C253.382 130.528 254.041 130.969 254.597 131.525C255.152 132.081 255.593 132.741 255.894 133.467C256.194 134.193 256.349 134.971 256.348 135.757L256.339 183.62Z" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M113.344 210.562H106.099C105.473 210.562 104.873 210.314 104.43 209.871C103.988 209.428 103.739 208.828 103.739 208.202V204.577H115.704V208.202C115.704 208.828 115.456 209.428 115.013 209.871C114.57 210.314 113.97 210.562 113.344 210.562Z" fill="#FFDCA7" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M253.979 210.562H246.731C246.105 210.562 245.505 210.314 245.062 209.871C244.62 209.428 244.371 208.828 244.371 208.202V204.577H256.339V208.202C256.339 208.828 256.09 209.428 255.648 209.871C255.205 210.314 254.605 210.562 253.979 210.562Z" fill="#FFDCA7" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M183.672 210.562H176.415C175.789 210.562 175.188 210.314 174.746 209.871C174.303 209.428 174.055 208.828 174.055 208.202V204.577H186.032V208.202C186.032 208.828 185.783 209.428 185.34 209.871C184.898 210.314 184.298 210.562 183.672 210.562Z" fill="#FFDCA7" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M255.839 204.077V183.632C255.839 182.912 255.698 182.199 255.422 181.533C255.147 180.867 254.743 180.263 254.234 179.753C253.725 179.244 253.12 178.84 252.455 178.565L252.455 178.565C251.79 178.289 251.077 178.147 250.357 178.147H109.724H109.724C109.004 178.147 108.29 178.288 107.625 178.564L255.839 204.077ZM255.839 204.077H104.239L104.239 183.632L104.239 183.632C104.239 182.911 104.38 182.198 104.656 181.533L104.656 181.533C104.932 180.867 105.336 180.262 105.845 179.753C105.845 179.753 105.845 179.753 105.845 179.753L255.839 204.077Z" fill="white" stroke="#B25B0C"/>
    <path d="M256.339 204.577H103.739V183.632C103.739 182.846 103.893 182.067 104.194 181.341C104.495 180.615 104.936 179.955 105.491 179.399C106.047 178.843 106.707 178.403 107.433 178.102C108.16 177.801 108.938 177.647 109.724 177.647H250.357C251.142 177.647 251.921 177.802 252.647 178.103C253.372 178.403 254.032 178.844 254.588 179.4C255.143 179.956 255.584 180.616 255.884 181.342C256.185 182.068 256.339 182.846 256.339 183.632V204.577Z" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M103.239 163.187V162.687H94.7708L89.6947 166.571C89.9709 165.905 90.3757 165.3 90.8859 164.791L90.886 164.791C91.3962 164.281 92.0018 163.877 92.6683 163.602L92.6683 163.602C93.3348 163.327 94.049 163.186 94.77 163.187H94.7708H103.239ZM103.239 163.187V204.077H91.7688C91.1078 204.077 90.4738 203.814 90.0064 203.347C89.539 202.88 89.2764 202.246 89.2764 201.585V168.672C89.2764 167.951 89.4185 167.237 89.6947 166.571L103.239 163.187Z" fill="white" stroke="#B25B0C"/>
    <path d="M91.7688 204.577H103.739V162.687H94.7708C93.984 162.686 93.2047 162.84 92.4775 163.14C91.7503 163.44 91.0894 163.881 90.5327 164.437C89.9759 164.992 89.5342 165.653 89.2328 166.379C88.9315 167.106 88.7764 167.885 88.7764 168.672V201.585C88.7764 202.378 89.0916 203.139 89.6528 203.701C90.2141 204.262 90.9752 204.577 91.7688 204.577Z" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M256.839 204.077V163.187H265.307L265.307 163.187C266.027 163.187 266.741 163.328 267.406 163.604L267.406 163.604C268.072 163.88 268.677 164.284 269.186 164.793C269.695 165.302 270.099 165.907 270.375 166.573L270.375 166.573C270.65 167.238 270.792 167.952 270.792 168.672L270.792 168.672L270.801 201.585C270.801 201.585 270.801 201.585 270.801 201.585C270.801 202.246 270.539 202.88 270.071 203.347C269.604 203.814 268.97 204.077 268.309 204.077H256.839Z" fill="white" stroke="#B25B0C"/>
    <path d="M271.301 201.585C271.301 202.378 270.986 203.139 270.425 203.701C269.864 204.262 269.102 204.577 268.309 204.577H256.339V162.687H265.307C266.093 162.687 266.871 162.841 267.597 163.142C268.324 163.443 268.984 163.884 269.54 164.439C270.095 164.995 270.536 165.655 270.837 166.381C271.137 167.108 271.292 167.886 271.292 168.672L271.301 201.585Z" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M207.963 215.794H207.963L207.964 215.807C207.974 216.239 207.899 216.669 207.741 217.072L207.741 217.072C207.583 217.474 207.346 217.841 207.044 218.151C206.742 218.46 206.381 218.706 205.983 218.874C205.584 219.042 205.156 219.129 204.724 219.129C204.292 219.129 203.864 219.042 203.465 218.874C203.067 218.706 202.706 218.46 202.404 218.151C202.102 217.841 201.865 217.474 201.707 217.072L201.707 217.072C201.549 216.669 201.474 216.239 201.484 215.807L201.485 215.807V215.794V181.13H207.963V215.794Z" fill="white" stroke="#B25B0C"/>
    <path d="M208.463 215.794C208.476 216.293 208.389 216.79 208.206 217.254C208.024 217.719 207.75 218.142 207.402 218.5C207.054 218.857 206.637 219.141 206.177 219.335C205.717 219.529 205.223 219.629 204.724 219.629C204.225 219.629 203.731 219.529 203.271 219.335C202.811 219.141 202.394 218.857 202.046 218.5C201.698 218.142 201.424 217.719 201.242 217.254C201.059 216.79 200.972 216.293 200.985 215.794V180.63H208.463V215.794Z" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M208.964 189.112V181.135H290.25V189.112H208.964Z" fill="white" stroke="#B25B0C"/>
    <path d="M290.75 180.635H208.464V189.612H290.75V180.635Z" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M305.71 177.645C305.71 178.038 305.633 178.427 305.482 178.79C305.332 179.153 305.111 179.483 304.834 179.76C304.556 180.038 304.226 180.258 303.863 180.408C303.5 180.558 303.111 180.635 302.718 180.635H196.496C196.097 180.646 195.699 180.577 195.326 180.432C194.953 180.287 194.613 180.068 194.326 179.789C194.04 179.51 193.811 179.177 193.656 178.809C193.5 178.44 193.42 178.044 193.42 177.644C193.42 177.244 193.5 176.848 193.656 176.479C193.811 176.111 194.04 175.777 194.326 175.498C194.613 175.219 194.953 175.001 195.326 174.856C195.699 174.71 196.097 174.641 196.496 174.653H302.718C303.111 174.652 303.5 174.73 303.863 174.88C304.226 175.03 304.557 175.25 304.834 175.528C305.112 175.806 305.333 176.136 305.483 176.5C305.633 176.863 305.711 177.252 305.71 177.645Z" fill="#FFDCA7" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M208.463 215.794C208.476 216.293 208.389 216.79 208.206 217.254C208.024 217.719 207.75 218.143 207.402 218.5C207.054 218.857 206.637 219.141 206.177 219.335C205.717 219.529 205.223 219.629 204.724 219.629C204.225 219.629 203.731 219.529 203.271 219.335C202.811 219.141 202.394 218.857 202.046 218.5C201.698 218.143 201.424 217.719 201.242 217.254C201.059 216.79 200.972 216.293 200.985 215.794V212.054H208.463V215.794Z" fill="#FFDCA7" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M297.731 215.794C297.731 216.654 297.39 217.478 296.782 218.086C296.174 218.694 295.35 219.035 294.491 219.035C293.631 219.035 292.807 218.694 292.199 218.086C291.591 217.478 291.25 216.654 291.25 215.794V181.13H297.731V215.794Z" fill="white" stroke="#B25B0C"/>
    <path d="M298.231 215.794C298.231 216.786 297.837 217.738 297.136 218.439C296.434 219.141 295.483 219.535 294.491 219.535C293.498 219.535 292.547 219.141 291.846 218.439C291.144 217.738 290.75 216.786 290.75 215.794V180.63H298.231V215.794Z" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M298.231 215.794C298.231 216.786 297.837 217.738 297.136 218.439C296.434 219.141 295.483 219.535 294.491 219.535C293.498 219.535 292.547 219.141 291.846 218.439C291.144 217.738 290.75 216.786 290.75 215.794V212.054H298.231V215.794Z" fill="#FFDCA7" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M296.733 165.675H263.82C262.994 165.675 262.324 166.345 262.324 167.171V173.156C262.324 173.982 262.994 174.652 263.82 174.652H296.733C297.559 174.652 298.229 173.982 298.229 173.156V167.171C298.229 166.345 297.559 165.675 296.733 165.675Z" fill="white" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M274.294 165.675H268.309V174.652H274.294V165.675Z" fill="#FFDCA7" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M290.748 156.697H257.835C257.009 156.697 256.339 157.367 256.339 158.194V164.178C256.339 165.005 257.009 165.675 257.835 165.675H290.748C291.574 165.675 292.244 165.005 292.244 164.178V158.194C292.244 157.367 291.574 156.697 290.748 156.697Z" fill="white" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M268.309 156.697H262.324V165.675H268.309V156.697Z" fill="#FFDCA7" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M253.137 139.582L242.599 170.762C242.334 171.545 242.754 172.394 243.537 172.658L249.207 174.575C249.99 174.839 250.839 174.419 251.104 173.636L261.642 142.456C261.906 141.674 261.486 140.824 260.703 140.56L255.033 138.644C254.25 138.379 253.401 138.799 253.137 139.582Z" fill="white" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M251.699 143.83L249.782 149.499L258.287 152.374L260.203 146.704L251.699 143.83Z" fill="#FFDCA7" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M220.433 168.172H220.434C221.155 168.173 221.869 168.032 222.535 167.757C223.201 167.481 223.807 167.078 224.317 166.568C224.827 166.059 225.232 165.454 225.508 164.788C225.785 164.122 225.927 163.408 225.927 162.688C225.927 162.687 225.927 162.687 225.927 162.687V153.72C225.927 153.058 225.665 152.424 225.197 151.957C224.73 151.489 224.096 151.227 223.435 151.227L208.473 151.227L220.433 168.172ZM220.433 168.172L211.465 168.172L211.465 168.672M220.433 168.172V168.672H211.465M211.465 168.672V168.172M211.465 168.672V168.172M211.465 168.172C210.011 168.172 208.616 167.594 207.588 166.565L207.588 166.565C206.56 165.537 205.982 164.142 205.982 162.688V153.72C205.982 153.059 206.245 152.425 206.712 151.958C207.179 151.49 207.812 151.228 208.473 151.227L211.465 168.172Z" fill="white" stroke="#B25B0C"/>
    <path d="M220.433 168.672H211.465C209.878 168.672 208.356 168.041 207.234 166.919C206.113 165.796 205.482 164.274 205.482 162.688V153.72C205.482 152.926 205.797 152.165 206.358 151.604C206.919 151.043 207.679 150.728 208.473 150.727H223.435C224.229 150.727 224.99 151.042 225.551 151.603C226.112 152.165 226.427 152.926 226.427 153.72V162.688C226.427 163.474 226.272 164.253 225.97 164.98C225.669 165.706 225.227 166.366 224.67 166.922C224.114 167.478 223.453 167.918 222.726 168.219C221.999 168.519 221.22 168.673 220.433 168.672Z" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M226.419 153.71H228.703C229.684 153.71 230.625 154.1 231.318 154.793C232.012 155.487 232.402 156.427 232.402 157.408C232.401 158.094 232.21 158.767 231.849 159.35C231.488 159.934 230.972 160.405 230.358 160.712L226.419 162.683" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M229.567 171.349C229.07 172.342 228.306 173.178 227.36 173.761C226.415 174.345 225.326 174.653 224.215 174.653H207.676C206.563 174.655 205.473 174.347 204.526 173.763C203.579 173.179 202.814 172.343 202.316 171.349C202.175 171.067 202.108 170.754 202.122 170.439C202.136 170.124 202.23 169.818 202.396 169.55C202.561 169.281 202.793 169.06 203.068 168.906C203.343 168.753 203.653 168.672 203.968 168.672H227.915C228.23 168.672 228.54 168.753 228.815 168.906C229.091 169.06 229.322 169.281 229.488 169.55C229.653 169.818 229.748 170.124 229.761 170.439C229.775 170.754 229.708 171.067 229.567 171.349Z" fill="white" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M223.204 122.937C221.789 122.453 220.255 122.439 218.832 122.896C217.408 123.354 216.17 124.259 215.302 125.477C214.434 126.694 213.982 128.16 214.014 129.655C214.046 131.15 214.56 132.595 215.479 133.774C215.432 133.796 215.385 133.805 215.338 133.829C214.627 134.179 214.031 134.724 213.619 135.401C213.207 136.077 212.996 136.857 213.012 137.649C213.027 138.441 213.267 139.213 213.705 139.873C214.142 140.533 214.759 141.055 215.482 141.378C216.206 141.701 217.006 141.811 217.79 141.695C218.573 141.579 219.308 141.243 219.907 140.725C220.507 140.207 220.946 139.529 221.174 138.77C221.402 138.012 221.41 137.204 221.195 136.441C222.822 136.394 224.38 135.777 225.597 134.697C226.814 133.617 227.613 132.144 227.854 130.534C228.095 128.925 227.762 127.282 226.914 125.894C226.066 124.505 224.757 123.458 223.215 122.937H223.204Z" fill="white" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M208.518 120.797C210.997 120.797 213.007 118.787 213.007 116.309C213.007 113.829 210.997 111.82 208.518 111.82C206.039 111.82 204.029 113.829 204.029 116.309C204.029 118.787 206.039 120.797 208.518 120.797Z" fill="white" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M174.065 174.659C175.653 173.071 176.545 170.917 176.545 168.672V163.189H138.654V168.672C138.654 170.917 139.546 173.071 141.135 174.659C142.723 176.247 144.876 177.14 147.122 177.14H168.077C170.322 177.14 172.476 176.247 174.064 174.659C174.064 174.659 174.065 174.659 174.065 174.659ZM174.065 174.659L174.418 175.013L174.065 174.659Z" fill="#FFDCA7" stroke="#B25B0C"/>
    <path d="M177.045 168.672C177.045 171.05 176.1 173.331 174.418 175.013C172.736 176.695 170.455 177.64 168.077 177.64H147.122C144.744 177.64 142.463 176.695 140.781 175.013C139.099 173.331 138.154 171.05 138.154 168.672V162.689H177.045V168.672Z" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M176.542 156.216L177.042 156.216L186.028 156.204C186.292 156.204 186.546 156.098 186.733 155.912C186.92 155.725 187.025 155.472 187.026 155.208C187.026 155.207 187.026 155.207 187.026 155.207M176.542 156.216L136.445 122.08C142.056 116.468 149.667 113.315 157.603 113.315C165.539 113.315 173.15 116.468 178.761 122.08C184.373 127.691 187.526 135.302 187.526 143.238V155.208L187.026 155.207M176.542 156.216V156.716V165.184M176.542 156.216V165.184M187.026 155.207L187.026 143.238C187.026 135.435 183.926 127.951 178.408 122.433L178.751 122.09L178.408 122.433C172.89 116.915 165.406 113.815 157.603 113.815C149.8 113.815 142.316 116.915 136.798 122.433L136.798 122.433C131.28 127.951 128.181 135.435 128.181 143.238V155.22C128.181 155.484 128.286 155.737 128.472 155.924C128.659 156.111 128.913 156.216 129.177 156.216H138.145H138.645V156.716V165.184H176.542M187.026 155.207L176.542 165.184" fill="white" stroke="#B25B0C"/>
    <mask id="path-43-inside-1_1882_4682" fill="white">
    <path d="M177.048 164.178H177.043V165.679H177.048V164.178Z"/>
    </mask>
    <path d="M177.048 164.178H177.043V165.679H177.048V164.178Z" fill="white"/>
    <path d="M177.048 164.178H178.048V163.178H177.048V164.178ZM177.043 164.178V163.178H176.043V164.178H177.043ZM177.043 165.679H176.043V166.679H177.043V165.679ZM177.048 165.679V166.679H178.048V165.679H177.048ZM177.048 163.178H177.043V165.178H177.048V163.178ZM176.043 164.178V165.679H178.043V164.178H176.043ZM177.043 166.679H177.048V164.679H177.043V166.679ZM178.048 165.679V164.178H176.048V165.679H178.048Z" fill="#B25B0C" mask="url(#path-43-inside-1_1882_4682)"/>
    <path d="M177.042 156.716V165.684H138.145V156.716H129.177C128.78 156.716 128.4 156.558 128.119 156.278C127.838 155.997 127.681 155.616 127.681 155.22V143.238C127.681 135.302 130.833 127.691 136.445 122.08C142.056 116.468 149.667 113.315 157.603 113.315C165.539 113.315 173.15 116.468 178.761 122.08C184.373 127.691 187.526 135.302 187.526 143.238V155.208C187.525 155.604 187.367 155.984 187.087 156.265C186.806 156.545 186.426 156.703 186.029 156.704L177.042 156.716Z" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M138.145 137.255V168.674" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M177.043 137.255V168.672" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M165.531 114.389C165.028 114.255 164.583 113.959 164.265 113.546C163.948 113.132 163.777 112.626 163.777 112.105V103.385H151.439V112.117C151.439 112.637 151.266 113.142 150.948 113.554C150.631 113.966 150.186 114.261 149.683 114.394C149.296 114.498 148.914 114.607 148.534 114.727C148.534 114.727 150.127 123.799 157.606 123.799C165.085 123.799 166.678 114.729 166.678 114.729C166.293 114.599 165.913 114.491 165.531 114.389Z" fill="white" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M138.147 168.672H135.155C133.568 168.672 132.046 168.041 130.924 166.919C129.801 165.798 129.17 164.276 129.17 162.689V156.716H138.138L138.147 168.672Z" fill="white" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M186.032 162.689C186.032 163.475 185.877 164.253 185.576 164.979C185.275 165.705 184.834 166.364 184.279 166.92C183.723 167.476 183.063 167.916 182.337 168.217C181.611 168.517 180.833 168.672 180.047 168.672H177.045V156.716H186.013L186.032 162.689Z" fill="white" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M184.47 174.036C184.585 173.06 184.506 172.07 184.239 171.125C183.971 170.179 183.52 169.295 182.911 168.523C182.301 167.752 181.547 167.108 180.688 166.628C179.831 166.148 178.886 165.842 177.91 165.727C176.934 165.613 175.945 165.691 174.999 165.959C174.053 166.226 173.169 166.678 172.398 167.287C171.626 167.896 170.982 168.651 170.502 169.509C170.023 170.367 169.717 171.311 169.602 172.287L165.59 206.46L180.458 208.211L184.47 174.036Z" fill="#FFDCA7" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M179.228 210.566C179.741 210.571 180.247 210.443 180.696 210.196C181.145 209.948 181.523 209.588 181.792 209.152C182.062 208.716 182.215 208.217 182.236 207.705C182.257 207.192 182.145 206.683 181.912 206.226C181.136 204.821 179.998 203.649 178.616 202.833C177.234 202.018 175.658 201.588 174.053 201.589H172.556C170.951 201.588 169.375 202.018 167.993 202.833C166.611 203.649 165.473 204.821 164.698 206.226C164.464 206.684 164.352 207.194 164.374 207.707C164.395 208.22 164.548 208.719 164.819 209.155C165.089 209.592 165.468 209.951 165.918 210.198C166.368 210.445 166.875 210.572 167.388 210.566H179.228Z" fill="white" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M130.726 174.036C130.611 173.061 130.69 172.072 130.958 171.127C131.225 170.182 131.676 169.299 132.285 168.528C132.894 167.758 133.648 167.114 134.506 166.635C135.363 166.156 136.306 165.851 137.282 165.736C138.257 165.622 139.246 165.7 140.191 165.968C141.136 166.235 142.019 166.686 142.79 167.295C143.56 167.904 144.204 168.658 144.683 169.516C145.162 170.373 145.468 171.317 145.582 172.292L149.606 206.465L134.738 208.216L130.726 174.036Z" fill="#FFDCA7" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M135.967 210.566C135.454 210.571 134.948 210.444 134.499 210.196C134.049 209.949 133.671 209.59 133.401 209.153C133.131 208.717 132.978 208.218 132.958 207.706C132.937 207.193 133.048 206.683 133.282 206.226C134.057 204.821 135.195 203.649 136.577 202.833C137.959 202.018 139.535 201.588 141.14 201.589H142.637C144.242 201.587 145.818 202.017 147.201 202.833C148.584 203.648 149.723 204.82 150.498 206.226C150.731 206.683 150.843 207.193 150.822 207.706C150.801 208.218 150.648 208.717 150.378 209.153C150.108 209.59 149.73 209.949 149.281 210.196C148.831 210.444 148.325 210.571 147.812 210.566H135.967Z" fill="white" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M178.541 92.9961C178.534 92.1446 178.232 91.3216 177.687 90.6672C177.142 90.0128 176.387 89.5674 175.551 89.4065V87.0205C175.578 84.6457 175.134 82.2887 174.244 80.0866C173.354 77.8845 172.036 75.8809 170.366 74.1918C168.696 72.5025 166.708 71.1616 164.516 70.2464C162.324 69.3312 159.973 68.8599 157.597 68.8599C155.222 68.8599 152.87 69.3312 150.679 70.2464C148.487 71.1616 146.499 72.5025 144.829 74.1918C143.159 75.8809 141.841 77.8845 140.951 80.0866C140.061 82.2887 139.616 84.6457 139.644 87.0205V89.4136C138.772 89.5776 137.989 90.0524 137.44 90.7498C136.892 91.4472 136.615 92.3199 136.661 93.2061C136.707 94.092 137.073 94.9313 137.691 95.568C138.309 96.2047 139.137 96.5955 140.021 96.6682C140.864 100.706 143.071 104.331 146.272 106.933C149.473 109.534 153.472 110.954 157.597 110.954C161.722 110.954 165.721 109.534 168.922 106.933C172.123 104.331 174.331 100.706 175.173 96.6682C176.091 96.5854 176.945 96.1632 177.568 95.484C178.191 94.8048 178.538 93.9176 178.541 92.9961Z" fill="white" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M153.107 100.622C154.436 101.506 155.996 101.978 157.591 101.978C159.187 101.978 160.747 101.506 162.075 100.622" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M154.107 91.5069C154.107 94.5362 151.651 96.9919 148.622 96.9919C145.592 96.9919 143.137 94.5362 143.137 91.5069C143.137 88.4777 145.592 86.022 148.622 86.022C151.651 86.022 154.107 88.4777 154.107 91.5069Z" fill="#FFDCA7" stroke="#B25B0C"/>
    <path d="M172.059 91.5069C172.059 94.5362 169.603 96.9919 166.574 96.9919C163.545 96.9919 161.089 94.5362 161.089 91.5069C161.089 88.4777 163.545 86.022 166.574 86.022C169.603 86.022 172.059 88.4777 172.059 91.5069Z" fill="#FFDCA7" stroke="#B25B0C"/>
    <path d="M154.604 91.5071C154.604 90.7135 154.92 89.9524 155.481 89.3911C156.042 88.8299 156.803 88.5146 157.597 88.5146C158.391 88.5146 159.152 88.8299 159.713 89.3911C160.274 89.9524 160.589 90.7135 160.589 91.5071" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M148.622 97.4919C151.927 97.4919 154.607 94.8124 154.607 91.5069C154.607 88.2015 151.927 85.522 148.622 85.522C145.316 85.522 142.637 88.2015 142.637 91.5069C142.637 94.8124 145.316 97.4919 148.622 97.4919Z" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M166.574 97.4919C169.879 97.4919 172.559 94.8124 172.559 91.5069C172.559 88.2015 169.879 85.522 166.574 85.522C163.268 85.522 160.589 88.2015 160.589 91.5069C160.589 94.8124 163.268 97.4919 166.574 97.4919Z" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M167.561 59.1745L167.56 59.1747C167.523 59.188 167.49 59.2099 167.463 59.2383L167.463 59.2384C167.436 59.2664 167.417 59.3007 167.405 59.3388C167.394 59.376 167.392 59.4153 167.399 59.4537C167.399 59.4541 167.399 59.4544 167.399 59.4547L166.907 59.5431C167.344 61.9031 164.241 63.0831 157.597 63.0831C144.479 63.0831 144.014 72.2842 144.098 74.5507L167.561 59.1745ZM167.561 59.1745C167.597 59.1613 167.636 59.1568 167.674 59.1614M167.561 59.1745L167.674 59.1614M167.674 59.1614C170.995 59.5746 174.228 61.8928 175.954 66.5933C177.666 71.2533 177.892 78.2626 175.171 88.0121H174.877C173.844 87.8582 172.899 87.3417 172.211 86.5547C171.516 85.7589 171.13 84.7397 171.124 83.683L171.124 83.6448L171.118 83.6071C171.032 83.0719 170.84 82.5596 170.554 82.0999L170.554 82.0998C170.267 81.6403 169.891 81.2427 169.448 80.9308C169.005 80.6188 168.504 80.3988 167.975 80.2839C167.446 80.1686 166.899 80.1608 166.366 80.261L166.366 80.2609L166.36 80.2621C160.578 81.4227 154.624 81.4227 148.843 80.2621L148.838 80.2613C148.305 80.1592 147.758 80.1657 147.227 80.2801L167.674 59.1614ZM140.058 88.0168H140.321C141.356 87.8643 142.304 87.3479 142.993 86.5595L142.993 86.5594C143.69 85.7628 144.076 84.7418 144.081 83.6834L144.081 83.6461L144.087 83.6091C144.17 83.0731 144.361 82.5593 144.647 82.0984C144.933 81.6375 145.309 81.2392 145.752 80.9268C146.195 80.6145 146.697 80.3944 147.227 80.2801L140.058 88.0168ZM140.058 88.0168C140.048 87.9652 140.038 87.9089 140.026 87.8482C139.96 87.487 139.874 86.9696 139.793 86.3482C139.631 85.1025 139.494 83.4525 139.585 81.8113C139.676 80.1594 139.995 78.5737 140.703 77.4153C141.389 76.2917 142.448 75.5506 144.133 75.5506H144.699L144.629 74.9892L144.629 74.989L144.629 74.9888L144.629 74.9886L140.058 88.0168Z" fill="white" stroke="#B25B0C"/>
    <path d="M167.735 58.6651C167.619 58.651 167.5 58.6644 167.39 58.7045C167.28 58.7444 167.18 58.8098 167.1 58.895C167.019 58.9804 166.96 59.0836 166.926 59.1961C166.893 59.3087 166.886 59.4274 166.907 59.5431C167.344 61.9031 164.241 63.0831 157.597 63.0831C142.637 63.0831 144.133 75.0506 144.133 75.0506C136.652 75.0506 139.649 88.5168 139.649 88.5168H140.357C141.524 88.3539 142.594 87.776 143.369 86.8886C144.145 86.0015 144.575 84.8644 144.581 83.6858C144.654 83.2162 144.821 82.7659 145.072 82.3621C145.322 81.9583 145.652 81.6093 146.04 81.3355C146.429 81.0618 146.868 80.869 147.333 80.7689C147.797 80.6686 148.277 80.6629 148.744 80.7524C154.591 81.926 160.612 81.926 166.458 80.7524C166.925 80.6646 167.405 80.6714 167.869 80.7724C168.333 80.8732 168.772 81.066 169.16 81.3395C169.549 81.6131 169.878 81.9616 170.13 82.3645C170.381 82.7676 170.549 83.2167 170.624 83.6858C170.63 84.8625 171.06 85.9975 171.834 86.8836C172.608 87.7696 173.676 88.3478 174.841 88.512H175.549C181.239 68.5205 174.796 59.543 167.735 58.6651Z" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M181.533 162.689C181.534 163.082 181.456 163.472 181.306 163.835C181.156 164.198 180.936 164.528 180.658 164.806C180.38 165.085 180.05 165.305 179.686 165.456C179.323 165.606 178.934 165.684 178.541 165.684H136.651C135.857 165.684 135.096 165.369 134.535 164.807C133.973 164.246 133.658 163.485 133.658 162.691V161.2C133.658 160.803 133.816 160.422 134.096 160.142C134.377 159.861 134.758 159.704 135.154 159.704H180.037C180.434 159.704 180.814 159.861 181.095 160.142C181.376 160.422 181.533 160.803 181.533 161.2V162.689Z" fill="white" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M136.651 133.26H178.541C179.917 133.26 181.033 134.376 181.033 135.752V156.702C181.033 158.079 179.917 159.194 178.541 159.194H136.651C135.274 159.194 134.158 158.079 134.158 156.702V135.752C134.158 134.376 135.274 133.26 136.651 133.26Z" fill="white" stroke="#B25B0C"/>
    <path d="M178.541 132.76H136.651C134.998 132.76 133.658 134.1 133.658 135.752V156.702C133.658 158.355 134.998 159.694 136.651 159.694H178.541C180.193 159.694 181.533 158.355 181.533 156.702V135.752C181.533 134.1 180.193 132.76 178.541 132.76Z" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M157.598 153.707C160.077 153.707 162.087 151.697 162.087 149.218C162.087 146.739 160.077 144.729 157.598 144.729C155.119 144.729 153.109 146.739 153.109 149.218C153.109 151.697 155.119 153.707 157.598 153.707Z" fill="#FFDCA7" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M159.093 140.238L157.599 141.737V144.729" stroke="#B25B0C" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default AdviseUponArrivalIllustration;
