import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { MixpanelProvider } from "react-mixpanel-browser";
import CssBaseline from "@mui/material/CssBaseline";
import { Analytics } from "@vercel/analytics/react";

import theme from "./theme";
import router from "./router";
import store from "./redux/store";

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <MixpanelProvider>
        <CssBaseline />
        <RouterProvider router={router} />
      </MixpanelProvider>
    </ThemeProvider>
    <Analytics />
  </Provider>
);

export default App;
