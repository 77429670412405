const ARIcon: React.FC = () => (
  <svg width="27" height="31" viewBox="0 0 27 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0713 3.5L13.5 1.5L16.9288 3.5" stroke="#1C1C1E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.5 1.5V6.5" stroke="#1C1C1E" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M16.9288 27.5L13.5 29.5L10.0713 27.5" stroke="#1C1C1E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.5 29.5V24.5" stroke="#1C1C1E" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M1.5 12.4695V8.50014L4.82187 6.53076" stroke="#1C1C1E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.5 8.5L5.70562 11" stroke="#1C1C1E" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M25.4996 18.5303V22.4996L22.1777 24.469" stroke="#1C1C1E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M25.5005 22.5L21.2949 20" stroke="#1C1C1E" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M4.82187 24.4996L1.5 22.4996V18.5303" stroke="#1C1C1E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.5 22.5L5.665 20" stroke="#1C1C1E" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M22.1777 6.53076L25.4996 8.50014V12.4695" stroke="#1C1C1E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M25.5005 8.5L21.2949 11" stroke="#1C1C1E" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M13.5 19.5V15.5L16.9287 13.5" stroke="#1C1C1E" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M13.5 15.5L10.0713 13.5" stroke="#1C1C1E" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
  </svg>
);

export default ARIcon;
