const surveyJSON = {
  pages: [
    {
      name: 'page1',
      elements: [
        {
          type: 'panel',
          name: 'residence-type-panel',
          elements: [
            {
              type: 'radiogroup',
              name: 'residence-type',
              isRequired: true,
              choices: [
                {
                  value: 'fully',
                  text: 'Fully detached single-family home',
                },
                {
                  value: 'semi',
                  text: 'Semi-detached single-family home',
                },
                { value: 'townhouse', text: 'Townhouse' },
                {
                  value: 'apartment',
                  text: 'Apartment or condo building',
                },
              ],
            }
          ],
          questionTitleLocation: 'hidden',
          title: 'What is your residence type?',
          state: 'expanded',
          isRequired: true,
          showNumber: true,
        },
        {
          type: 'panel',
          name: 'pets-panel',
          elements: [
            {
              type: 'radiogroup',
              name: 'pets',
              isRequired: true,
              choices: [
                { value: 'yes-dog', text: 'Yes - Dog' },
                { value: 'yes-cat', text: 'Yes - Cat' },
                { value: 'yes-other', text: 'Yes - Other' },
                { value: 'no', text: 'No' },
              ],
            },
          ],
          questionTitleLocation: 'hidden',
          title: 'Do you have any pets that will be present at time of delivery? If so, what kind?',
          state: 'expanded',
          isRequired: true,
          showNumber: true,
        },
      ],
    },
  ],
};

export default surveyJSON;